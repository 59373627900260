/* eslint-disable react/display-name */
import React, { useState } from 'react'

import { graphql } from 'gatsby'
import { Slice } from '@components/slice'
import { Layout } from '@components/layout'

type Props = {
  data: any
  pageContext: any
}

const SubpageTemplate: React.FC<Props> = props => {
  const { meta, header, seo, main, footer } = props.data
  const slices = main?.data?.body
  return (
    <Layout
      seo={seo}
      header={header}
      footer={footer}
      // theme={theme}
      activeMeta={meta}
    >
      <Slice allSlices={slices} />
    </Layout>
  )
}

export default SubpageTemplate

export const pageQuery = graphql`
  query SubpageTemplate($uid: String, $locale: String) {
    meta: prismicSubpage(uid: { eq: $uid }, lang: { eq: $locale }) {
      lang
      type
      uid
      alternate_languages {
        uid
        lang
      }
    }
    seo: prismicSubpage(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }
    header: prismicMenu(lang: { eq: $locale }) {
      ...header
    }
    main: prismicSubpage(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        body {
          ... on PrismicSubpageDataBodyFullscreenImage {
            ...fullscreenImage
          }
          ... on PrismicSubpageDataBodyFullscreenVideo {
            ...fullscreenVideo
          }
          ... on PrismicSubpageDataBodyContent {
            ...contentFragment
          }
          ... on PrismicSubpageDataBodyContentWithImage {
            ...contentWithImageSubpage
          }
          ... on PrismicSubpageDataBodyGallery {
            ...gallerySubpage
          }
          ... on PrismicSubpageDataBodyTitle {
            ...titleFragment
          }
          ... on PrismicSubpageDataBodyVideoEmbed {
            ...videoEmbed
          }
          ... on PrismicSubpageDataBodyAddCustomEmbedOrHtmlCode {
            ...codeFragment
          }
        }
      }
    }

    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
